import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleApp from './GoogleApp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="758924629462-t1ajtdrn6pfkmh1m30riugmspi59ck5d.apps.googleusercontent.com">
    <React.StrictMode>
      <GoogleApp />
    </React.StrictMode>
  </GoogleOAuthProvider>,
);
